import "../styles/posts.css";
import image from "../../../assets/images/stanislava_image_kitchen.jpg";
import { Helmet } from "react-helmet";

export default function MyFeminePath() {
	return (
		<div className="singlePost">
			<div className="singlePostWrapper">
				<img src={image} alt="break-up" className="singlePostImg" />
				<h1 className="singlePostTitle">Моят път към женската ми същност</h1>
				<Helmet>
					<title>Моят път към женската ми същност</title>
					<meta
						name="description"
						content="Преди осем години, за първи път, чух изречението, отправено към мен
						от мъжа до мен, което гласеше „Държиш се като мъж“. Честно да си
						призная, не го допуснах до съзнанието си, не и в самото начало. Тъй
						като го чувах често, обаче, първоначалния му допуск беше по – скоро
						като негативно убеждение, от колкото като нещо, което да ме накара
						да се събудя и да осъзная, че е необходимо да обърна внимание именно
						на женската си енергия."
					/>
				</Helmet>
				<div className="singlePostDesc">
					<p>
						Преди осем години, за първи път, чух изречението, отправено към мен
						от мъжа до мен, което гласеше „Държиш се като мъж“. Честно да си
						призная, не го допуснах до съзнанието си, не и в самото начало. Тъй
						като го чувах често, обаче, първоначалния му допуск беше по – скоро
						като негативно убеждение, от колкото като нещо, което да ме накара
						да се събудя и да осъзная, че е необходимо да обърна внимание именно
						на женската си енергия.
					</p>
					<p>Какъв беше живота ми по това време?</p>
					<p>
						Живеех живот пълен с отговорности, който подхождаше на един мъж
						повече, от колкото на една жена. Естествено не се оплаквам, нито
						искам да влизам в ролята на жертва, защото в крайна сметка това са
						нашите избори, ние избираме как да живеем и какво поведение да
						допускаме и следваме в живота си. Интересното беше, че въпреки
						факта, че бях в партньорски взаимоотношения, и то с мъж, който
						изключително много държеше на своята мъжка енергия и сила, аз не се
						чувствах жена, нямах поведение на такава и нямах пространството да
						бъда такава. Моето търсене, обаче, ме върна малко по – назад във
						времето. Още преди срещата ми с тогавашния партньор. Търсех модели
						на поведение в моето детство, които следвам. Обследвах връзката
						майка – дъщеря, баща - дъщеря, както и всички мои предишни връзки.
						Работех много с блокажите по тези две оси - партньорски
						взаимоотношения и взаимоотношенията в рожденото ми семейство. Не
						мога да отрека, че беше болезнено.
					</p>
					<p>
						Когато търсим причините за дефицити в дадено поведение или дефицити
						в женска/мъжка енергия, винаги е добре да започнем търсенето от
						периода дори на нашето раждане.
					</p>
					<p>
						Често се оказва, обаче, че блокирането на нашата женска енергия е
						вследствие на обстоятелствата в живота ни, например, нагърбването с
						отговорности за дома и отглеждането на децата, които изцяло стават
						наши. Или връзка с манипулативен или нарцистичен партньор. Или
						връзка с мъж, който няма връзка със своята мъжка енергия или баланс
						между мъжката и женската енергия. Парадокса е, че именно за такъв
						тип мъже винаги не сте достатъчни, вашето поведение и визия никога
						не са такива, каквито те искат. И затова скъпи жени, вие не носите
						отговорност. Ключа към вашата женска енергия е да бъдете автентични
						и достатъчни. Само тогава ще имате възможността да се наслаждавате
						на живота, да творите.
					</p>
					<p className="pt-10">Запишете си лична консултация с мен, ако:</p>
					<ul>
						<li className="tick-shaped">
							Чувствате, че сте в задушаващи взаимоотношения
						</li>
						<li className="tick-shaped">
							Имате нужда да се потопите в своята женственост и да я разгърнете
						</li>
						<li className="tick-shaped">
							Работите в мъжка среда и не знаете как да балансирате и да
							компенсирате
						</li>
						<li className="tick-shaped">
							Отглеждате сами децата си и се грижите сами за дома си
						</li>
						<li className="tick-shaped">
							Имате сложни взаимоотношения с майка си и не я приемате
						</li>
						<li className="tick-shaped">
							Баща ви не е бил присъстващ в живота ви
						</li>
					</ul>
					<p className="pt-10">Очаквам ви!</p>
				</div>
			</div>
		</div>
	);
}
