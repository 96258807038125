import "../styles/posts.css";
import image from "../../../assets/images/stanislava_taro.jpg";
import ContactForm from "../../contact-form/contactForm";
import { Helmet } from "react-helmet";

export default function Taro() {
	return (
		<div className="singlePost">
			<div className="singlePostWrapper">
				<img src={image} alt="taro cards" className="singlePostImg" />
				<h1 className="singlePostTitle">Таро</h1>
				<Helmet>
					<title>Таро</title>
					<meta
						name="description"
						content="Тарото, откри за мен нов свят. Не мога да опиша
                        вълнението си, когато докосна колодата си ли когато
                        свиквам с нова такава. Използвам тарото в консултативния
                        процес, когато усетя, че въпрос, над който разсъждаваме
                        с клиента, се нуждае и от езотеричен прочит."
					/>
				</Helmet>
				<div className="singlePostDesc">
					<p>
						Винаги ме е привличала езотериката. Когато преди година разбрах, че
						моята таро учителка ще проведе нов курс по таро, изобщо не се
						замислих и се записах. Последните 6 години си служа успешно с
						ангелска терапия и карти, както и много други подобни гадателски
						подходи.
					</p>
					<p>
						Тарото, откри за мен нов свят. Не мога да опиша вълнението си,
						когато докосна колодата си ли когато свиквам с нова такава.
						Използвам тарото в консултативния процес, когато усетя, че въпрос,
						над който разсъждаваме с клиента, се нуждае и от езотеричен прочит.
					</p>
					<p>
						Практикувам таро в ежедневието си и предлагам и услугите дневен,
						седмичен, месечен рийдинг, както и отговор на въпрос/ситуация.
					</p>
				</div>
				{/* <div className="priceList">
					<p>
						Индивидуална онлайн коучинг сесия с разглеждане на въпрос с помощта на таро – 70 лв/50 мин.
					</p>
					<p>
						Отговор на въпрос онлайн – 20 лв/20 мин.
					</p>
					<p>
						Седмичен рийдинг онлайн– 30 лв/30 мин.
					</p>
				</div> */}
			</div>
			<div className="contact-form-container">
				<h3>Изпратете запитване</h3>
				<ContactForm />
			</div>
		</div>
	);
}
