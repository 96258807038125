import "../styles/posts.css";
import image from "../../../assets/images/astrology.jpg";
import { Helmet } from "react-helmet";
import ContactForm from "../../contact-form/contactForm";

export default function Astrology() {
	return (
		<div className="singlePost">
			<div className="singlePostWrapper">
				<img src={image} alt="taro" className="singlePostImg" />
				<h1 className="singlePostTitle">
					Кармична астрология
				</h1>
				<Helmet>
					<title>Кармична астрология</title>
				</Helmet>
				<div className="singlePostDesc">
					<p>
						Астрологичната карта показва не само уроците и дълговете ни от
						минали животи, но и висшият духовен път, който душата е избрала
						преди да се прероди. Ние имаме избор дали да тръгнем по този път или
						не, но все пак е добре да знаем нещо за посоката.
					</p>
					<p>
						Кармичната астрология показва потенциала от стойности, способности и
						убеждения, с който влизаме в този живот. Да следваш своите кармични
						уроци и посока носи често успокоение и яснота в живота и много
						често-видими успехи. По-лесно все пак е да плуваш по течението.
						Обикновено периода около 36-тата година на живота е много
						показателен - областите на живота, в които изпълняваме кармичните си
						цели бележат видим разцвет и подем, и обратно - тези, в които си
						вървим по старите модели често тръгват надолу или даже се
						разрушават.
					</p>
					<p>
						Другото, което вълнува хората са връзките, взаимоотношенията им с
						техните партньори, деца, родители, кои са уроците, които трябва да
						усвоим в тези взаимоотношения. На този въпрос отговарят кармичните
						аспекти в синастрията.
					</p>
					<p>
						Добре е да знаем, че кармичен не значи доживотен, задължителен или
						принудителен. По-често ние имаме връзки за определен период от време
						с някой, който ни показва нещо за самите нас и след това и двамата
						сме свободни да поемем по пътя си. Ако кармата е наистина много
						тежка може да сме принудени да се грижим за някой болен и да не
						можем да си тръгнем, но тези случаи са единични. Не е необходимо да
						програмираме съзнанието си с мисълта, че кармично сме обременени или
						задължени.
					</p>
					<p>
						В края на краищата всичко е карма, всяко наше действие създава
						кармата за следващите ни животи и така или иначе преди да постигнем
						Нирвана не можем да излезем от спиралата. Така че е добре
						информацията за кармата също да се ползва със здрав разум.
					</p>
					<p>
						Оналайн 30 минутна консултация и предоставяне на подробен анализ в
						писмен вид, както и препоръки.
					</p>
				</div>
				<div className="priceList">
					<p>Цена на услугата: 100 лева</p>
				</div>
			</div>
			<div className="contact-form-container">
				<h3>Изпратете запитване</h3>
				<ContactForm />
			</div>
		</div>
	);
}
